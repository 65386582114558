import React from 'react'
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import { BreadcrumbLite } from '../components/common/BreadcrumbLite'
import BreadHome from '../components/BreadHome'
// import { FirebaseContext } from '../components/Firebase'
import MarketBadge from '../components/MarketBadge'
import Markets from '../components/featured/markets'
import styled from 'styled-components'

const RightCol = styled.div`
  text-align: right;
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`

const LocationTemplate = (props) => {


  const renderPlayer = (edge, index) => {

    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}>
          <BS.Card.Body>
            <BS.Row>
              <BS.Col sm={6}>
                < Link to={`/business/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name}</Link> <MarketBadge market={edge.node.what} />
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                funds raised: <b>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.totalRaised)}</b>
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
        </BS.Card>
      </section>
    )
  }
  const metaDescription =`The ${props.pageContext.city}, ${props.pageContext.country} tech scene! Dig deep into businesses and investors fully mapped out for you.`
  return (

    <section>
      <Helmet>
        <html lang="en" />
        <title>{props.pageContext.city}, {props.pageContext.country} - Private equity movements By UnicornAlert</title>
        
        <meta name="description" content={metaDescription} />
      </Helmet>
      <BS.Container style={{ paddingLeft: 0, paddingRight: 11, maxWidth: 1240 }}>
      <BreadcrumbLite>
      <BreadHome/>
        <BS.Breadcrumb.Item> <Link to={`/`}>Data</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>{props.pageContext.city}, {props.pageContext.country}</BS.Breadcrumb.Item>
      </BreadcrumbLite>

      <BS.Row style={{display: 'block'}}>
              <BS.Col sm={12}>
              <h1>Startups and Scale-ups in {props.pageContext.city}, {props.pageContext.country}</h1>
                <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>{props.pageContext.city} tech businesses ranked by the total amount of capital raised</h2>
                </BS.Col>
        </BS.Row>

        <BS.Row>
          <BS.Col sm={8}>
           
            {props.data.allBusiness.edges.map(renderPlayer)}

          </BS.Col>
          <BS.Col sm={4}>


            <Markets />



          </BS.Col>
        </BS.Row>

      </BS.Container>

    </section >

  );
}

export const query = graphql`
query IndexQuery5 ($city: String!)
{ 
  allBusiness(filter: {city: {eq: $city}}, sort: {fields: totalRaised, order: DESC}) {
 
    edges {
      node {
        id
        name
        currency
        totalRaised
        what
      
      }
    }
    totalCount
  }
}
`

export default LocationTemplate